export const reviewsDataHome = [
  { name: 'maxim-yurin', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'kristina-aidumova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'artem-sadovnikov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'polina-malakhova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'ekaterina-fedorenko', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataInstagramAnalytics = [
  { name: 'andrey-komissarov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'artem-sadovnikov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'stanislav-son', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataVkAnalytics = [
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'andrey-komissarov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'artem-sadovnikov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'stanislav-son', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataInstagramStoriesStats = [
  { name: 'irina-minaeva', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'eugen-skvortsov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'stanislav-son', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-litvin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataInstagramCompetitors = [
  { name: 'irina-minaeva', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'eugen-skvortsov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'stanislav-son', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-litvin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataAuditInstagram = [
  { name: 'irina-minaeva', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'eugen-skvortsov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-litvin', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'stanislav-son', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataDownloadCommentsInstagram = [
  { name: 'irina-minaeva', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'eugen-skvortsov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'stanislav-son', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-litvin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataMessages = [
  { name: 'irina-minaeva', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'eugen-skvortsov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'stanislav-son', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-litvin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataAbout = [
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'artem-sadovnikov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'polina-malakhova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'maxim-yurin', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-litvin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataContacts = [
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'artem-sadovnikov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'polina-malakhova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'maxim-yurin', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-litvin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataPartners = [
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'artem-sadovnikov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'polina-malakhova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'maxim-yurin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataAccountStats = [
  { name: 'kristina-aidumova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'ksenia-vezhchanina', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'ksenia-kozhemiakina', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'eugen-skvortsov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'stanislav-son', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'ivan-ilin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataDirectInstagram = [
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'artem-sadovnikov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'polina-malakhova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'maxim-yurin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataAutoposting = [
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'artem-sadovnikov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'polina-malakhova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'maxim-yurin', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-litvin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataMonitoring = [
  { name: 'viktoria-chachelova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'ksenia-vezhchanina', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'ekaterina-fedorenko', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'ksenia-kozhemiakina', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'andrey-mazur', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataReports = [
  { name: 'ekaterina-fedorenko', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'andrey-komissarov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'ksenia-kozhemiakina', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'kristina-aidumova', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataBloggersCheck = [
  { name: 'irina-minaeva', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'ksenia-vezhchanina', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'andrey-komissarov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'stanislav-son', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'polina-malakhova', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataKpi = [
  { name: 'stanislav-son', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'eugen-skvortsov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'polina-malakhova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'viktoria-chachelova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-litvin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataPricing = [
  { name: 'ksenia-vezhchanina', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'ksenia-kozhemiakina', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'eugen-skvortsov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'stanislav-son', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'ivan-ilin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataStatsPower = [
  { name: 'andrey-mazur', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'kristina-aidumova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'andrey-komissarov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'polina-malakhova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'alex-litvin', locale: ['ru', 'en', 'ua', 'kz'] },
]

export const reviewsDataServices = [
  { name: 'alex-tkachuk', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'kristina-aidumova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'ekaterina-fedorenko', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'polina-malakhova', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'artem-sadovnikov', locale: ['ru', 'en', 'ua', 'kz'] },
  { name: 'maxim-yurin', locale: ['ru', 'en', 'ua', 'kz'] },
]
