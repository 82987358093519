import useFormatMessage from '@utils/useFormatMessage'

import styles from '@components/reviews/review/reviewAuthor/reviewAuthor.module.scss'

export default function ReviewAuthor({ id }) {
  const t = useFormatMessage()

  return (
    <div className={styles.reviewAuthor}>
      <div className={styles.reviewAuthor__box}>
        <div className={styles.reviewAuthor__imgWrapper}>
          <picture>
            <source
              className={styles.reviewAuthor__img}
              srcSet={`/images/reviews/${id}.webp`}
              type="image/webp"
            />
            <source
              className={styles.reviewAuthor__img}
              srcSet={`/images/reviews/${id}.webp 1x, /images/reviews/${id}@2x.webp 2x`}
              type="image/webp"
            />
            <img
              className={styles.reviewAuthor__img}
              src={`/images/reviews/${id}.png`}
              srcSet={`/images/reviews/${id}.png 1x, /images/reviews/${id}@2x.png 2x`}
              alt={t(`reviews.names.${id}`)}
              loading="lazy"
            />
          </picture>
        </div>
      </div>

      <div className={styles.reviewAuthor__box}>
        <p className={styles.reviewAuthor__name}>{t(`reviews.names.${id}`)}</p>

        <p className={styles.reviewAuthor__title}>
          {t(`reviews.positions.${id}`)}
        </p>
      </div>
    </div>
  )
}
