import { useRouter } from 'next/router'
import cns from 'classnames'

import useFormatMessage from '@utils/useFormatMessage'

import Review from '@components/reviews/review/review'
import Slider from './slider/slider'

import styles from './reviews.module.scss'

export default function Reviews({ data = [] }) {
  const t = useFormatMessage()
  const { locale } = useRouter()

  const localReviews = data.filter(review => review.locale.includes(locale))
  const sliderElements = localReviews.map((review, i) => (
    <Review id={review.name} key={i} />
  ))

  if (!sliderElements.length) return

  return (
    <div className={styles.reviews}>
      <div className="wrapper">
        <h2 className={cns(styles.title, `main-title main-title--h2`)}>
          {t(`reviews.mainTitle`)}
        </h2>
        <Slider elements={sliderElements} />
      </div>
    </div>
  )
}
