import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import cns from 'classnames'

import SliderArrow from '@icons/slider-arrow.svg'

import styles from './slider.module.scss'

const Slider = ({ elements }) => (
  <div className={styles.wrapper}>
    <Swiper
      slidesPerView={1}
      speed={1000}
      loop={true}
      navigation={{
        nextEl: '#nextControlReviewsSlider',
        prevEl: '#prevControlReviewsSlider',
      }}
      modules={[Navigation]}
    >
      {elements.map((element, index) => (
        <SwiperSlide key={index}>{element}</SwiperSlide>
      ))}
    </Swiper>
    <div className={styles.arrows}>
      <button
        id="prevControlReviewsSlider"
        className={cns(styles.arrow, styles.arrow__prev)}
        type="button"
      >
        <SliderArrow />
      </button>
      <button
        id="nextControlReviewsSlider"
        className={styles.arrow}
        type="button"
      >
        <SliderArrow />
      </button>
    </div>
  </div>
)

Slider.propTypes = {
  elements: PropTypes.node,
}

export default Slider
