import cns from 'classnames'

import useFormatMessage from '@utils/useFormatMessage'
import ReviewAuthor from '@components/reviews/review/reviewAuthor/reviewAuthor'

import styles from './review.module.scss'

export default function Review({ id }) {
  const t = useFormatMessage()

  if (!id) return
  return (
    <div className={cns(styles.review__item, `splide__slide`)}>
      <div className={styles.review__content}>
        <div className={styles.reviewAuthor__imgWrapper}>
          <picture>
            <source
              className={styles.reviewAuthor__img}
              srcSet={`/images/reviews/${id}.webp`}
              type="image/webp"
            />
            <source
              className={styles.reviewAuthor__img}
              srcSet={`/images/reviews/${id}.webp 1x, /images/reviews/${id}@2x.webp 2x`}
              type="image/webp"
            />
            <img
              className={styles.reviewAuthor__img}
              src={`/images/reviews/${id}.png`}
              srcSet={`/images/reviews/${id}.png 1x, /images/reviews/${id}@2x.png 2x`}
              alt={t(`reviews.names.${id}`)}
              loading="lazy"
            />
          </picture>
        </div>
        <div className={styles.review__textWrapper}>
          <p className={cns(styles.review__text, `js-review-text`)}>
            {t(`reviews.texts.${id}`)}
            <button
              className={cns(styles.review__readMore, `js-read-more-button`)}
              type="button"
            >
              {t(`reviews.readMore`)}
            </button>
          </p>

          <div className="review__author">
            <ReviewAuthor id={id} />
          </div>
        </div>
      </div>
    </div>
  )
}
